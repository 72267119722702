import React, { createContext, useState, useEffect } from 'react';
import { navigate as gatsbyNavigate } from 'gatsby';
import PropTypes from 'prop-types';
import translations from 'translations';

export const LangContext = createContext();

const LangProvider = ({ children }) => {
  const [lang, setLang] = useState('pl');

  const setAppLang = language => {
    setLang(language);
  };

  const translate = phrase => {
    if (translations[lang]) return translations[lang][phrase];
    return null;
  };

  const navigate = (to, options = {}) => {
    const translatedTo =
      lang === 'en' && to !== -1 ? `/en${to.replace('en_', '')}` : to;
    gatsbyNavigate(translatedTo, options);
  };

  const locationPath =
    typeof window !== 'undefined' ? window.location.pathname : '';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const prefix = locationPath.slice(0, 4);
      if (locationPath.length > 3) {
        if (prefix[0] === '/' && prefix[3] === '/') {
          setAppLang(`en`);
        } else {
          setAppLang(`pl`);
        }
      } else if (locationPath.length === 3 && locationPath.includes('en')) {
        setAppLang(`en`);
      } else {
        setAppLang(`pl`);
      }
    }
  }, [locationPath]);

  return (
    <LangContext.Provider value={{ lang, setAppLang, translate, navigate }}>
      {children}
    </LangContext.Provider>
  );
};

LangProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default LangProvider;
