import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import GlobalStyle from 'theme/GlobalStyle';
import { theme } from 'theme/mainTheme';
import smoothscroll from 'smoothscroll-polyfill';
import LangProvider from 'contexts/LangContext';
import PageLoader from 'components/shared/PageLoader';
import 'swiper/swiper-bundle.css';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const MainLayout = ({ children }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      smoothscroll.polyfill();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LangProvider>
        <GlobalStyle />
        <PageLoader />
        <Wrapper id="home">{children}</Wrapper>
      </LangProvider>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
