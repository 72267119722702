export default {
  pl: {
    premiereIn: 'Premiera za',
    viewMore: 'Zobacz więcej',
    oneDay: 'dzień',
    coupleDays: 'dni',
    watchVideo: `Zobacz odcinek`,
    readMore: `Czytaj wywiad`,
    readLess: `Czytaj mniej`,
    premiereSoon: 'Już wkrótce<br /> premiera',
    salesOffice: `Biuro Sprzedaży`,
    allEpisodes: `Wszystkie odcinki`,
    seeTrailer: `Zobacz zwiastun`,
    investKomfortURL: 'https://www.investkomfort.pl/',
  },
};
