export default {
  en: {
    premiereIn: 'Premiere in',
    viewMore: 'See more',
    oneDay: 'day',
    coupleDays: 'days',
    watchVideo: `See episode`,
    readMore: `Read more`,
    readLess: `Read less`,
    premiereSoon: 'Coming soon',
    salesOffice: `Sales Office`,
    allEpisodes: `All episodes`,
    seeTrailer: `Watch trailer`,
    investKomfortURL: 'https://www.investkomfort.pl/en/',
  },
};
