import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: ${({ height }) => height};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;

const Spinner = styled.figure`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  & > div {
    position: absolute;
    width: 38px;
    height: 38px;
    border: 4px solid #fff;
    border-radius: 50%;
    border-color: #fff transparent transparent transparent;
    animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 80px;
    height: 80px;
    & > div {
      width: 64px;
      height: 64px;
      border-width: 5px;
    }
  }
`;

const Loader = ({ height, hidden }) => (
  <Wrapper hidden={hidden} height={height}>
    <Spinner>
      <div />
      <div />
      <div />
      <div />
    </Spinner>
  </Wrapper>
);

Loader.propTypes = {
  height: PropTypes.string,
  hidden: PropTypes.bool,
};

Loader.defaultProps = {
  height: '100%',
  hidden: false,
};

export default Loader;
