import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Loader from './Loader';

const Wrapper = styled.div`
  position: fixed;
  top: -100px;
  left: -100px;
  bottom: -100px;
  right: -100px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.7s;
  background: ${({ theme }) => theme.colors.dark};
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const PageLoader = () => {
  const [shouldRender, setShouldRender] = useState(true);
  const [webFontsLoaded, setWebFontsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      await document.fonts.ready;
      setTimeout(() => setWebFontsLoaded(true), 300);
      setTimeout(() => setShouldRender(false), 1300);
    })();
  });

  return (
    <>
      {shouldRender && (
        <Wrapper visible={!webFontsLoaded}>
          <Loader hidden={webFontsLoaded} />
        </Wrapper>
      )}
    </>
  );
};

export default PageLoader;
