export const breakpoints = {
  xs: 440,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xlg: 1280,
  xxl: 1600,
};

export const theme = {
  fonts: {
    mainFont: `proxima-nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  },
  colors: {
    primary: `#dd4363`,
    secondary: `#dd4363`,
    white: '#FFFFFF',
    white100: '#F7F7F7',
    white200: '#F6F6F6',
    white300: '#F5F5F5',
    dark: '#191919',
    dark100: '#1D1D1D',
    dark200: '#343434',
    dark300: '#5C5550',
    gray: '#606060',
    gray100: '#CFCFCF',
    gray200: '#B7B7B7',
    gray300: '#E1DAD5',
    red: '#DD4363',
    red100: '#f44336',
    green: '#75C500',
  },
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  fontSize: {
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.8rem',
    lg: '2.1rem',
    xl: '6rem',
    xxl: '8rem',
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
};
